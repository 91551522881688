import { Api } from './api';
import { Session } from './session';
import { User } from './user';
import { Utils } from './utils';

const MESSAGE_NAME_LOGIN_SUCCESS = 'identity-login-success';
const MESSAGE_NAME_WINDOW_CLOSE = 'identity-close-window';
const WINDOW_NAME_LOGIN = 'identity-login-window';
const REALM_IDENTITY = 'identity';

interface LoginResponse {
  session_expiry: number;
}

export class IdentityService {
  private static windowCloseEventListenerRegistered: boolean;

  public me: User = null;

  private readonly identityRealm: Api;
  private authenticatedChangedCallbacks: Array<
    (authenticated: boolean) => void
  > = [];

  constructor(private serviceBaseUrl: URL, private loginUrl: URL) {
    this.registerRemoteLoginFailedHandler();

    this.serviceBaseUrl.pathname += 'api/v1/';
    this.identityRealm = new Api(
      this.serviceBaseUrl.toString(),
      REALM_IDENTITY,
    );

    Session.setSessionExpiryCallback(() => {
      this.me = null;
      for (const authenticatedChangedCallback of this
        .authenticatedChangedCallbacks) {
        authenticatedChangedCallback(this.me !== null);
      }
    });
  }

  // This has to be separate and cannot be called in the constructor, because an authenticatedChanged callback first needs to be registered
  public async detectAuthenticated(): Promise<void> {
    if (!Session.isValid()) {
      await this.ensureSession();
    }
    if (Session.isValid()) {
      this.finalizeAuthentication(Session.getExpiry());
    }
  }

  public async ensureSession(): Promise<boolean> {
    try {
      await this.identityRealm.renewAccessToken();
    } catch (error) {
      if (error.status !== 401) {
        throw error;
      }

      return false;
    }

    return true;
  }

  public authenticatedChanged(
    callback: (authenticated: boolean) => void,
  ): IdentityService {
    this.authenticatedChangedCallbacks.push(callback);
    return this;
  }

  public async passwordLogin(
    email: string,
    password: string,
    totp?: string,
  ): Promise<User> {
    email = email.trim();

    const passwordHash = await Utils.sha512(email.toLowerCase() + password);

    const payload = {
      email,
      password: passwordHash,
    };
    if (totp !== undefined) {
      payload['totp'] = totp;
    }
    const loginResponse = await this.identityRealm.unauthenticatedServiceFetch<LoginResponse>(
      'user/me/session',
      'POST',
      payload,
    );
    this.finalizeAuthentication(loginResponse.session_expiry * 1000);

    return this.me;
  }

  public async remoteLogin(): Promise<User> {
    if (Session.isValid()) {
      return this.me;
    }

    await new Promise<void>((resolve, reject) => {
      const loginMessageEventListener = (event) => {
        window.removeEventListener('message', loginMessageEventListener, false);
        if (event.data.type === MESSAGE_NAME_LOGIN_SUCCESS) {
          this.finalizeAuthentication(event.data.expiry);
          resolve();
        } else if (event.data.type === MESSAGE_NAME_WINDOW_CLOSE) {
          reject();
        }
      };
      window.addEventListener('message', loginMessageEventListener, false);
      window.open(
        this.loginUrl.toString(),
        WINDOW_NAME_LOGIN,
        'location=0,menubar=0,status=0,titlebar=0,toolbar=0',
      );
    });

    return this.me;
  }

  /*public async ssoLogin(ssoProvider: string, ssoToken: string): Promise<User> {
        const loginResponse = await this.api.unauthenticatedServiceFetch<LoginResponse>('user/me/session', 'POST', {
            ssoProvider,
            ssoToken,
        });
        this.finalizeAuthentication(loginResponse.session_expiry * 1000);

        return this.me;
    }*/

  public withRealm(realm: string): Api {
    return new Api(this.serviceBaseUrl.toString(), realm);
  }

  public user(id?: string): User {
    return new User(this.identityRealm, id);
  }

  /*public async requestSetPassword(email: string, password: string, recaptchaResponse: string): Promise<void> {
        await this.api.unauthenticatedServiceFetch(`user/${email}/password`, 'PATCH', {
            'password': await User.hashPassword(email, password),
            'g-recaptcha-response': recaptchaResponse,
        });
    }*/

  public async requestSetPassword(
    email: string,
    recaptchaResponse: string,
  ): Promise<void> {
    await this.identityRealm.unauthenticatedServiceFetch(`resetpw`, 'POST', {
      email,
      'g-recaptcha-response': recaptchaResponse,
    });
  }

  public async confirmSetPassword(
    requestId: string,
    email: string,
    password: string,
  ): Promise<void> {
    await this.identityRealm.unauthenticatedServiceFetch(
      `resetpw/${requestId}`,
      'POST',
      {
        password: await User.hashPassword(email, password),
      },
    );
  }

  public async activateUser(activationId: string): Promise<void> {
    await this.identityRealm.unauthenticatedServiceFetch(
      `activation/${activationId}`,
      'POST',
    );
  }

  // The session expiry should be stored before calling this, because it is also used in the detectAuthenticated method, which does not setExpiry the session expiry
  private finalizeAuthentication(sessionExpiry: number): void {
    this.me = new User(this.identityRealm, 'me');

    for (const authenticatedChangedCallback of this
      .authenticatedChangedCallbacks) {
      authenticatedChangedCallback(this.me !== null);
    }
    Session.notifyAuthentication();

    // This needs to be executed in all cases, because it triggers the timeout for the automatic session expiry detection
    Session.setExpiry(sessionExpiry);

    // Check for the current URL to prevent the window from closing if it is not the login page but was opened with window.open
    if (opener && location.host === this.loginUrl.host) {
      opener.postMessage(
        {
          type: MESSAGE_NAME_LOGIN_SUCCESS,
          expiry: Session.getExpiry(),
        },
        '*',
      );
      window.close();
    }

    // the calling app requested a redirect to another url
    const queryParams = new URLSearchParams(window.location.search);
    if (queryParams.has('redirect_uri')) {
      window.location.href = queryParams.get('redirect_uri');
    }
  }

  private registerRemoteLoginFailedHandler(): void {
    // This is used to indicate a failed login attempt, if the login process was requested from another window
    if (opener && !IdentityService.windowCloseEventListenerRegistered) {
      window.addEventListener('beforeunload', () => {
        opener.postMessage(
          {
            type: MESSAGE_NAME_WINDOW_CLOSE,
          },
          '*',
        );
      });

      // Only register the event listener once, even if the class is instanced multiple times
      IdentityService.windowCloseEventListenerRegistered = true;
    }
  }
}
