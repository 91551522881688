import { AccessToken } from './access-token';
import { Session } from './session';

interface AccessTokenResponse {
  access_token: string;
  access_expiry: number;
  session_expiry: number;
}

export class Api {
  public accessToken: AccessToken;

  constructor(private serviceBaseUrl: string, private realm: string) {
    this.accessToken = new AccessToken(realm);
  }

  public async unauthenticatedServiceFetch<T>(
    endpoint: string,
    method: string,
    data?: unknown,
    additionalHeaders?: HeadersInit
  ): Promise<T> {
    return await (await Api.throwingFetch(`${this.serviceBaseUrl}${endpoint}`, {
      body: JSON.stringify(data),
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        ...additionalHeaders,
      },
      method,
    })).json() as Promise<T>;
  }

  public async authenticatedServiceFetch<T>(
    endpoint: string,
    method: string,
    data?: unknown,
    additionalHeaders?: HeadersInit
  ): Promise<T> {
    const response = await this.authenticatedFetch(`${this.serviceBaseUrl}${endpoint}`, {
      body: JSON.stringify(data),
      cache: 'no-cache',
      mode: 'cors',
      credentials: 'include',
      headers: {
        'content-type': 'application/json',
        ...additionalHeaders,
      },
      method,
    });

    return await response.json();
  }

  public async authenticatedFetch(
      input: RequestInfo,
      init?: RequestInit
  ): Promise<Response> {
    // Check if a authenticated user has an access token and that it is not expired
    if (!this.accessToken.isValid()) {
      // The access token is expired, authenticatedServiceFetch a new one
      try {
        await this.renewAccessToken();
      } catch (responseError) {
        // Just throw the error, if it is something other than an authentication failure
        if (responseError.status !== 401) {
          throw responseError;
        }

        await Session.authenticated();
        await this.renewAccessToken();
      }
    }

    try {
      // the access token validation was incorrect, this can occur if
      //    the access token got blacklisted (e.g. logout)
      //    the client system time is behind
      return await this.fetchWithAccessToken(input, init);
    } catch (responseError) {
      if (responseError.status === 401) {
        this.accessToken.expire();
        return await this.authenticatedFetch(input, init);
      }
      throw responseError;
    }
  }

  public async renewAccessToken(): Promise<void> {
    try {
      // Get a new access token
      const accessTokenResponse = await this.unauthenticatedServiceFetch<AccessTokenResponse>(
          `realm/${this.realm}/token`,
          'POST',
      );

      Session.setExpiry(accessTokenResponse.session_expiry * 1000);
      this.accessToken.store(accessTokenResponse.access_token);
    } catch(responseError) {
      // The response had an error, if the status is 401 (Session expired / missing) the application has to re-authenticate
      if (responseError.status === 401) {
        AccessToken.removeAll();
        Session.expire();
      }
      throw responseError;
    }
  }

  private async fetchWithAccessToken(
      input: RequestInfo,
      init?: RequestInit
  ): Promise<Response> {
    return Api.throwingFetch(input, {
      mode: 'same-origin', // this is needed for older browser, which specified a default of 'omit', but can be overwritten
      ...init,
      headers: {
        Authorization: `Bearer ${this.accessToken.get()}`,
        ...init?.headers
      },
    });
  }

  private static async throwingFetch(
      input: RequestInfo,
      init?: RequestInit
  ): Promise<Response> {
    const response = await fetch(input, init);
    if (!response.ok) {
      throw response;
    }
    return response;
  }
}
