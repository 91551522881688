import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, UrlTree } from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {RouteStateTransferService} from './route-state-transfer.service';

@Injectable()
export class LanguageRedirectGuard  {

  constructor(private router: Router, private translate: TranslateService, private routeStateTransferService: RouteStateTransferService) {
  }

  public canActivate(next: ActivatedRouteSnapshot): UrlTree | boolean {

    // If the language is known, everything is ok
    const availableLanguages = this.translate.getLangs();
    if (availableLanguages.includes(next.params['language'])) {
      this.translate.use(next.params['language']);
      return true;
    }

    const browserLanguage = this.translate.getBrowserLang();
    const useLanguage = browserLanguage && availableLanguages.includes(browserLanguage) ? browserLanguage : availableLanguages[0];
    const currentState = this.router.getCurrentNavigation()?.extras.state; // TODO This does not yet work in Angular https://github.com/angular/angular/issues/27148
    this.routeStateTransferService.store(currentState);

    // If the language is not set, redirect to the home page with the current browser language
    if (!next.params['language']) {
      return this.router.createUrlTree([useLanguage], {
        fragment: next.fragment ?? undefined,
        queryParams: next.queryParams,
        // state: currentState, // see currentState declaration
      });
    }

    // We have a language route that's not known, assume the language is the page and redirect
    return this.router.createUrlTree([useLanguage, next.params['language']], {
      fragment: next.fragment ?? undefined,
      queryParams: next.queryParams,
      // state: currentState, // see currentState declaration
    });
  }

}
