import {Component, ViewChild} from '@angular/core';
import {NgForm} from '@angular/forms';
import {GoogleTagManagerService} from '../../providers/google-tag-manager.service';
import {UserConsentService} from '../../providers/user-consent.service';

export const necessaryConsentName = 'Necessary';

@Component({
  selector: 'app-privacy-consent-selector',
  templateUrl: './privacy-consent-selector.component.html',
  styleUrls: ['./privacy-consent-selector.component.scss'],
})
export class PrivacyConsentSelectorComponent {

  private performanceConsent = this.googleTagManager.consent;
  private necessaryConsent = this.userConsent.forName(necessaryConsentName);

  @ViewChild('form', { static: true }) public form!: NgForm;
  public necessaryConsentGiven$ = this.necessaryConsent.given$;
  public performanceConsentGiven$ = this.performanceConsent.given$;

  constructor(private googleTagManager: GoogleTagManagerService, private userConsent: UserConsentService) { }

  public submitPreferences() {
    this.necessaryConsent.give();

    if (this.form.controls['performance'].value !== true) {
      this.performanceConsent.revoke();
    } else {
      this.performanceConsent.give();
    }
  }

  public consentAll() {
    this.necessaryConsent.give();
    this.performanceConsent.give();
  }

}
