<div class="loading" [class.active]="isLoading"></div>
<header>
  <ng-content select="[slot=header-top]"></ng-content>
  <div class="bottom">
    <a class="logo" [routerLink]="'/' + currentLanguage" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}">
      <img alt="XOrbit logo" src="/assets/img/xorbit-logo.svg">
      XOrbit
    </a>
    <div class="navigation-links">
      <ng-content select="[slot=header-center]"></ng-content>
    </div>

    <div></div> <!-- this div is to center the navigation-links -->
    <button aria-label="Menu" class="button menu-button seamless" [class.active]="sideActive" (click)="toggleSideMenu()">
        <span class="menu-icon">
          <span></span>
        </span>
    </button>
  </div>
</header>
<app-overlay class="side" [active]="sideActive" (click)="toggleSideMenu()">
  <div class="button-container">
    <ng-content select="[slot=side]"></ng-content>
  </div>
</app-overlay>
