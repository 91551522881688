import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {FormsModule} from '@angular/forms';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {TranslateModule} from '@ngx-translate/core';
import {IdentityModule} from '@skforge-ug/ngx-identity';
import {RecaptchaModule} from 'ng-recaptcha';
import {CountdownComponent} from './components/countdown/countdown.component';
import {ExpansionPanelComponent} from './components/expansion-panel/expansion-panel.component';
import { OverlayComponent } from './components/overlay/overlay.component';
import {DurationPipe} from './providers/duration.pipe';
import {EqualsValidatorDirective} from './providers/equals-validator.directive';
import {LocalizedDatePipe} from './providers/localized-date.pipe';
import {LocalizedDecimalPipe} from './providers/localized-decimal.pipe';
import {TimeAgoPipe} from './providers/time-ago.pipe';
import { TimeDiffPipe } from './providers/time-diff.pipe';

@NgModule({
  imports: [
    CommonModule,
    FontAwesomeModule,
  ],
  declarations: [
    EqualsValidatorDirective,
    CountdownComponent,
    DurationPipe,
    TimeAgoPipe,
    ExpansionPanelComponent,
    LocalizedDatePipe,
    LocalizedDecimalPipe,
    TimeDiffPipe,
    OverlayComponent,
  ],
  providers: [

  ],
    exports: [
        FormsModule,
        TranslateModule,
        FontAwesomeModule,
        IdentityModule,
        RecaptchaModule,
        EqualsValidatorDirective,
        CountdownComponent,
        TimeAgoPipe,
        TimeDiffPipe,
        LocalizedDatePipe,
        LocalizedDecimalPipe,
        ExpansionPanelComponent,
        OverlayComponent,
    ],
})
export class SharedModule {
}
