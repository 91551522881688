const SESSION_EXPIRY_NAME = 'identity-session-expiry';

export class Session {
  public static isValid(): boolean {
    const storedSessionExpiry = Session.getExpiry();
    // Check if the session expiry exists
    if (!isNaN(storedSessionExpiry)) {
      // Check if the token has not expired yet
      if (storedSessionExpiry >= Date.now()) {
        return true;
      }
    }
    return false;
  }

  public static setSessionExpiryCallback(callback: () => void): void {
    Session.sessionExpiryCallback = callback;
  }

  public static notifyAuthentication(): void {
    if (Session.authenticatedPromise != null) {
      Session.authenticatedResolve();
    }
  }

  public static async authenticated(): Promise<void> {
    if (Session.authenticatedPromise == null) {
      Session.authenticatedPromise = new Promise<void>((resolve) => {
        Session.authenticatedResolve = resolve;
      }).then(() => {
        // This tells authenticated() that the promise was already resolved
        Session.authenticatedPromise = null;
      });
    }

    return Session.authenticatedPromise;
  }

  public static getExpiry(): number {
    return parseInt(localStorage.getItem(SESSION_EXPIRY_NAME), 10);
  }

  public static setExpiry(expiry: number): void {
    clearTimeout(Session.sessionExpiryTimeout);
    Session.sessionExpiryTimeout = setTimeout(
      Session.expire,
      expiry - Date.now(),
    );

    localStorage.setItem(SESSION_EXPIRY_NAME, expiry.toString());
  }

  public static expire(): void {
    clearTimeout(Session.sessionExpiryTimeout);
    localStorage.removeItem(SESSION_EXPIRY_NAME);

    Session.sessionExpiryCallback();
  }

  private static sessionExpiryCallback: () => void;
  private static sessionExpiryTimeout: number;
  private static authenticatedPromise: Promise<void>;
  private static authenticatedResolve: () => void;
}
