import {Injectable} from '@angular/core';

// taken from NavigationBehaviorOptions.state
export type RouteState = {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [k: string]: any;
} | undefined;

// As route state transfer with guards does not yet work, this is a workaround https://github.com/angular/angular/issues/27148
@Injectable({
  providedIn: 'root',
})
export class RouteStateTransferService {

  private storage: RouteState;

  public store(data: RouteState): void {
    this.storage = data;
  }

  public retrieve(): RouteState {
    const storage = this.storage;
    this.storage = undefined;
    return storage;
  }

}
