<app-overlay [active]="(necessaryConsentGiven$ | async) === false" data-nosnippet>
  <div class="container">
    <section>
      <h3 translate>page.privacy_consent_selector.title</h3>
      <p translate>
        page.privacy_consent_selector.short_explanation
        <a href="/standalone/de/privacy.html" target="_blank" translate>page.privacy_consent_selector.link_label</a>
      </p>
    </section>
    <section>
      <h3 translate>page.privacy_consent_selector.preferences_headline</h3>
      <form (ngSubmit)="submitPreferences()" #form="ngForm">
        <span class="checkbox">
          <input id="necessary" type="checkbox" checked disabled>
          <label for="necessary" translate>page.privacy_consent_selector.preference.necessary</label>
        </span>
        <span class="checkbox">
          <input id="performance" [ngModel]="performanceConsentGiven$ | async" #performance="ngModel" name="performance" type="checkbox">
          <label for="performance" translate>page.privacy_consent_selector.preference.performance</label>
        </span>
        <button type="submit" class="button seamless" translate>action.confirm</button> <!-- The seamless class is intentional to lead users to accept all -->
      </form>
      <button class="button" (click)="consentAll()" translate>action.allow_all</button>
    </section>
  </div>
</app-overlay>

