import { AccessToken } from './access-token';
import { Api } from './api';
import { Session } from './session';
import { Utils } from './utils';

export interface UserSession {
  readonly id: string;
  readonly userAgent: string;
  readonly remoteAddress: string;
  readonly lastSeen: Date;
  readonly expires: Date;
  readonly createdAt: Date;
}

interface GetUserSessionResult {
  id: string;
  user_agent: string;
  remote_addr: string;
  last_seen: number;
  expires: number;
  created_at: number;
}

interface GetUserResult {
  id: string;
  email: string;
  lang: string;
  avatar: string;
  blocked_until: number;
  created_at: number;
  updated_at: number;
}

export class User {
  public static async hashPassword(
    email: string,
    password: string,
  ): Promise<string> {
    return await Utils.sha512(email.trim().toLowerCase() + password);
  }

  private readonly isSelf: boolean; // Needed, because id can be overwritten by fetchAttributes

  private id: string;
  private email: string;
  private language: string;
  private avatar: string;
  private blockedUntil: Date;
  private createdAt: Date;
  private updatedAt: Date;

  private sessions: UserSession[];

  constructor(private api: Api, id?: string) {
    if (id === 'me') {
      this.isSelf = true;
    }

    this.id = id;
  }

  public async passwordCreate(
    email: string,
    password: string,
    recaptchaResponse: string,
    referrer?: string,
  ): Promise<User> {
    email = email.trim();

    await this.api.unauthenticatedServiceFetch('user', 'POST', {
      email: email,
      'g-recaptcha-response': recaptchaResponse,
      password: await User.hashPassword(email, password),
      referrer: referrer,
    });

    this.email = email;

    return this;
  }

  public async ssoCreate(
    ssoProvider: string,
    ssoToken: string,
    recaptchaResponse: string,
    referrer?: string,
  ): Promise<User> {
    await this.api.unauthenticatedServiceFetch('user', 'POST', {
      'g-recaptcha-response': recaptchaResponse,
      ssoProvider: ssoProvider,
      ssoToken: ssoToken,
      referrer: referrer,
    });

    return this;
  }

  public async fetchSessions(): Promise<UserSession[]> {
    const sessions = await this.api.authenticatedServiceFetch<GetUserSessionResult[]>(
      `user/${this.id}/session`,
      'GET',
    );
    sessions.forEach((session, index) => {
      this.sessions[index] = {
        id: session.id,
        userAgent: session.user_agent,
        remoteAddress: session.remote_addr,
        lastSeen: new Date(session.last_seen * 1000),
        expires: new Date(session.expires * 1000),
        createdAt: new Date(session.created_at * 1000),
      };
    });

    return this.sessions;
  }

  public async fetchAttributes(): Promise<User> {
    const attributes = await this.api.authenticatedServiceFetch<GetUserResult>(
      `user/${this.id}`,
      'GET',
    );

    this.id = attributes.id;
    this.email = attributes.email;
    this.avatar = attributes.avatar;
    this.language = attributes.lang;
    this.blockedUntil = new Date(attributes.blocked_until);
    this.createdAt = new Date(attributes.created_at);
    this.updatedAt = new Date(attributes.updated_at);

    return this;
  }

  public async getId(): Promise<string> {
    if (this.id === 'me' || this.id === undefined) {
      await this.fetchAttributes();
    }

    return this.id;
  }

  /*public async requestSetEmail(email: string): Promise<User> {
        await this.api.authenticatedServiceFetch(`user/${this.id}`, 'PATCH', {
            email,
        });

        return this;
    }*/

  public async getEmail(): Promise<string> {
    if (this.email === undefined) {
      await this.fetchAttributes();
    }

    return this.email;
  }

  /*public async requestSetPassword(password: string): Promise<User> {
        await this.api.authenticatedServiceFetch(`user/${this.id}`, 'PATCH', {
            password: await User.hashPassword(await this.getEmail(), password),
        });

        return this;
    }*/

  public async setLanguage(language: string): Promise<User> {
    await this.api.authenticatedServiceFetch(`user/${this.id}`, 'PATCH', {
      language,
    });

    this.language = language;

    return this;
  }

  public async getLanguage(): Promise<string> {
    if (this.language === undefined) {
      await this.fetchAttributes();
    }

    return this.language;
  }

  public async setAvatar(avatar: string): Promise<User> {
    await this.api.authenticatedServiceFetch(`user/${this.id}`, 'PATCH', {
      avatar,
    });

    this.avatar = avatar;

    return this;
  }

  public async getAvatar(): Promise<string> {
    if (this.avatar === undefined) {
      await this.fetchAttributes();
    }

    return this.avatar;
  }

  public async getBlockedUntil(): Promise<Date> {
    if (this.blockedUntil === undefined) {
      await this.fetchAttributes();
    }

    return this.blockedUntil;
  }

  public async getUpdatedAt(): Promise<Date> {
    if (this.updatedAt === undefined) {
      await this.fetchAttributes();
    }

    return this.updatedAt;
  }

  public async getCreatedAt(): Promise<Date> {
    if (this.createdAt === undefined) {
      await this.fetchAttributes();
    }

    return this.createdAt;
  }

  /*public async confirmRequest(requestId: string, secret: string): Promise<User> {
        await this.api.unauthenticatedServiceFetch(`user/${this.id}/request/${requestId}`, 'POST', {
            secret,
        });
        return this;
    }*/

  public async logout(sessionId?: string): Promise<void> {
    try {
      // The logout is possible without an accessToken, but needs a valid session (else the user would already be logged out)
      if (sessionId !== undefined) {
        await this.api.unauthenticatedServiceFetch(
          `user/${this.id}/session/${sessionId}`,
          'DELETE',
        );
      } else {
        // Delete the current session
        if (this.isSelf) {
          await this.api.unauthenticatedServiceFetch(`user/me/session`, 'DELETE');
        }
      }
    } catch (error) {
      throw error;
    } finally {
      // Only do this, if this is the current user and session
      if (
        this.isSelf &&
        (sessionId === undefined ||
          this.api.accessToken.getClaims().sub === this.id)
      ) {
        // Always remove all accessTokens, even if the authenticatedServiceFetch failed
        AccessToken.removeAll();
        Session.expire();
      }
    }
  }

  public async delete(): Promise<void> {
    await this.api.authenticatedServiceFetch(`user/${this.id}`, 'DELETE');
  }
}
