import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {TranslateService} from '@ngx-translate/core';
import {BehaviorSubject, combineLatest, Observable, of, Subject} from 'rxjs';
import {catchError, map, shareReplay, startWith, switchMap, tap, throttleTime} from 'rxjs/operators';

export interface NewsServiceConfiguration {
  url: URL;
  loadAmount: number;
}

export const NewsServiceConfigurationToken = new InjectionToken<NewsServiceConfiguration>('newsServiceConfiguration');

export interface NewsArticle {
  id: number;
  title: string;
  content: string;
  date: Date;
  sticky: Date;
}

interface NewsArticleResult {
  id: number;
  title: string;
  content: string;
  date: number;
  sticky: string;
}

export interface NewsErrors {
  network?: boolean;
  internal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class NewsService {

  public readonly articles$?: Observable<NewsArticle[] | undefined>;

  private readonly errorSubject$ = new Subject<NewsErrors>();
  public readonly error$ = this.errorSubject$.pipe(shareReplay(1));

  private readonly refresh$ = new BehaviorSubject<void>(undefined);

  private loadAmount = this.configuration.loadAmount;

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private httpClient: HttpClient,
    @Inject(NewsServiceConfigurationToken) private configuration: NewsServiceConfiguration,
    private translate: TranslateService,
  ) {
    // Only initialize on the client
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.articles$ = combineLatest([
      this.translate.onLangChange.asObservable().pipe(
        map((langChangeEvent) => langChangeEvent.lang),
        startWith(this.translate.currentLang),
      ),
      this.refresh$,
    ]).pipe(
      throttleTime(500),
      tap(() => {
        this.errorSubject$.next(undefined);
      }),
      switchMap(([language]) =>
        this.httpClient.get<NewsArticleResult[]>(`${this.configuration.url.toString()}/${language}`),
      ),
      catchError((error: HttpErrorResponse) => {
        if (error.status === 0) {
          this.errorSubject$.next({
            network: true,
          });
        } else {
          this.errorSubject$.next({
            internal: true,
          });
        }
        return of(undefined);
      }),
      map((articles) => articles ? articles.slice(0, this.loadAmount) : articles),
      map(NewsService.transform),
      shareReplay(1),
    );
  }

  private static transform(universeInfoResult?: NewsArticleResult[]): NewsArticle[] | undefined {
    if (universeInfoResult === undefined) {
      return;
    }

    return universeInfoResult.map((result) => {

      const article: NewsArticle = {
        id: result.id,
        content: result.content,
        title: result.title,
        date: new Date(result.date * 1000),
        sticky: new Date(parseInt(result.sticky, 10) * 1000),
      };

      return article;
    });
  }

  public refresh() {
    this.refresh$.next();
  }

  public loadMore(amount?: number) {
    this.loadAmount += amount || this.configuration.loadAmount;
    this.refresh();
  }

}
