<nav>
  <a routerLink="news" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.news.title</a>
  <a routerLink="universes" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.universes.title</a>
  <a *ngIf="(authenticated | async) === false" routerLink="register" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.register.title</a>
  <a *ngIf="(authenticated | async) === false" routerLink="login" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.login.title</a>
  <!--a *ngIf="(authenticated | async)" routerLink="/account" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.account.title</a-->
  <a routerLink="about" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>page.about.title</a>
  <a href="//dsc.gg/xorbit" translate>link.forum</a>
  <a href="//savekb.xorbit.de" translate>link.battle_reports</a>
  <button class="text-button link" type="button" (click)="logout()" translate><i class="fas fa-sign-out-alt"></i> action.logout</button>
</nav>
