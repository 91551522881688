import {Component, HostBinding, Input} from '@angular/core';

@Component({
  selector: 'app-overlay',
  templateUrl: './overlay.component.html',
  styleUrls: ['./overlay.component.scss'],
})
export class OverlayComponent {

  private activeValue = false;
  @Input() set active(active: boolean) {
    this.activeValue = active;
    this.activeAttribute = this.activeValue ? true : null;
  }
  get active(): boolean {
    return this.activeValue;
  }

  @HostBinding('attr.active') public activeAttribute: true | null = null;

}
