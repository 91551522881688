import {Component} from '@angular/core';
import {IdentityService} from '@skforge-ug/ngx-identity';

@Component({
  selector: 'app-navigation',
  templateUrl: './navigation.component.html',
  styleUrls: ['./navigation.component.scss'],
})
export class NavigationComponent {

  public authenticated = this.identityService.authenticated;

  constructor(private identityService: IdentityService) {}

  public logout() {
    this.identityService.get().me.logout();
  }

}
