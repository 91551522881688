import {Component, OnDestroy} from '@angular/core';
import {NavigationCancel, NavigationEnd, NavigationError, NavigationStart, Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnDestroy {

  public sideActive = false;
  public isLoading = false;
  public currentLanguage = this.translateService.currentLang;

  private ngUnsubscribe$ = new Subject<void>();

  constructor(router: Router, private translateService: TranslateService) {
    router.events.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.isLoading = true;
      } else if (event instanceof NavigationEnd || event instanceof NavigationError || event instanceof NavigationCancel) {
        this.isLoading = false;
      }
    });

    this.translateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((langChangeEvent: LangChangeEvent) => {
      this.currentLanguage = langChangeEvent.lang;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  public toggleSideMenu(): void {
    this.sideActive = !this.sideActive;
  }

}
