export class Utils {
  public static async sha512(str: string): Promise<string> {
    if (window['sha512']) {
      // If the IE11 polyfill is available, use it.
      return window['sha512'](str);
    }

    const buffer = new TextEncoder().encode(str);
    const hashedBuffer = await crypto.subtle.digest('SHA-512', buffer);
    return Utils.hex(hashedBuffer);
  }

  public static getCookieValue(name: string): string {
    const startIndex = `; ${document.cookie}`.indexOf(`; ${name}=`);
    if (startIndex === -1) {
      return null;
    }
    let sub = `${document.cookie};`.substring(startIndex);
    sub = sub.substring(0, sub.indexOf(';'));
    return sub.substring(sub.indexOf('=') + 1);
  }

  public static removeCookie(
    name: string,
    domain = document.domain,
    path = '/',
  ): void {
    document.cookie = `${name}=; domain=${domain}; path=${path} Max-Age=0;`;
  }

  private static hex(buffer): string {
    const hexCodes = [];
    const view = new DataView(buffer);
    for (let i = 0; i < view.byteLength; i += 4) {
      const value = view.getUint32(i);
      const stringValue = value.toString(16);
      const padding = '00000000';
      const paddedValue = (padding + stringValue).slice(-padding.length);
      hexCodes.push(paddedValue);
    }

    return hexCodes.join('');
  }
}
