import {Environment} from './environment.interface';

export const environment: Environment = {
  production: true,
  recaptchaKey: '6LdXtzsUAAAAALUeoIuR05Y9a91pBFhIwMLF2wcV',
  identity: {
    serviceBaseUrl: new URL('https://id.dev.de-fra1.xorbit.space'),
    loginUrl: new URL('https://dev.xorbit.space')
  },
  universeMetaDataService: {
    url: new URL('https://api.dev.de-fra1.xorbit.space/universeInfo')
  },
  news: {
    url: new URL('https://api.dev.de-fra1.xorbit.space/news'),
    loadAmount: 3
  },
  googleTagManager: {
    id: 'GTM-K7J9JTH',
    pageViewEventName: 'SPA Page View',
    preview: {
      name: 'env-2',
      auth: 'q21MTkGV1CvhW3V4waxBiw',
    }
  },
  sentry: {
    dataSourceName: 'https://230333423a2640c18f29636e94e1b106@o486559.ingest.sentry.io/5544027',
    tracingOrigins: ['https://dev.xorbit.space'],
    tracesSampleRate: 1.0,
    environment: 'Staging'
  }
};
