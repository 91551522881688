import {NgModule} from '@angular/core';

import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {SharedModule} from '../shared/shared.module';
import {FooterNavigationComponent} from './footer-navigation/footer-navigation.component';
import {MenuComponent} from './menu/menu.component';
import {NavigationComponent} from './navigation/navigation.component';
import { PrivacyConsentSelectorComponent } from './privacy-consent-selector/privacy-consent-selector.component';
import {ShellComponent} from './shell.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    RouterModule,
  ],
  declarations: [
    ShellComponent,
    MenuComponent,
    NavigationComponent,
    FooterNavigationComponent,
    PrivacyConsentSelectorComponent,
  ],
  exports: [
    ShellComponent,
  ],
})
export class ShellModule {
}
