import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {Inject, Injectable, InjectionToken, PLATFORM_ID} from '@angular/core';
import {isPlatformServer} from '@angular/common';
import {BehaviorSubject, Observable, of, Subject} from 'rxjs';
import {catchError, map, shareReplay, switchMapTo, tap} from 'rxjs/operators';

export interface UniverseMetaDataServiceConfiguration {
  url: URL;
}

export const UniverseMetaDataServiceConfigurationToken = new InjectionToken<UniverseMetaDataServiceConfiguration>('universeMetaDataServiceConfiguration');

export interface UniverseMetaData {
  name: string;
  image: string;
  url: string;
  registered: boolean;
  closed?: {
    until?: Date;
    reason: string;
  };
  settings: {
    maximumPlanetsWithoutResearch: number;
    speeds: {
      build: number;
      resource: number;
      fleet: number;
    };
    debreeUnitPercent: {
      fleet: number;
      defense: number;
    }
    defenseRebuildProbability: number;
    startDate: Date;
    endDate?: Date;
  };
  metrics: {
    activePlayers: number;
    sentFleetsLast24h: number;
  };
}

interface UniverseInfoResult {
  displayName: string;
  loginUrl: string;
  building_speed: number;
  fleet_speed: number;
  resource_multiplier: string;
  tf_fleet: string;
  tf_defense: string;
  rebuild: string;
  max_galaxy: string;
  max_system: string;
  max_player_planets: string;
  without: number[];
  expowithout: number[];
  random_home_planet: string;
  runtime_limited: string;
  moon_createmulti: number;
  moon_createmax: number;
  active_players: string;
  flying_fleets: string;
  parameterMapping: {
    email: string;
    password: string;
  };
  time: number;
  image: string;
  close_reason: string;
  close_end_time: number;
  close_start_time: number;
  universe_start_time: number;
  universe_end_time: number;
}

export interface UniverseErrors {
  network?: boolean;
  internal?: boolean;
}

@Injectable({
  providedIn: 'root',
})
export class UniverseMetaDataService {

  public readonly universes$?: Observable<UniverseMetaData[] | undefined>;

  private readonly universesErrorSubject$ = new Subject<UniverseErrors>();
  public readonly universesError$ = this.universesErrorSubject$.pipe(shareReplay(1));

  private readonly refreshUniverses$ = new BehaviorSubject<void>(undefined);

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    private httpClient: HttpClient,
    @Inject(UniverseMetaDataServiceConfigurationToken) private configuration: UniverseMetaDataServiceConfiguration,
  ) {
    // Only initialize on the client
    if (isPlatformServer(this.platformId)) {
      return;
    }

    this.universes$ = this.refreshUniverses$.pipe(
      tap(() => {
        this.universesErrorSubject$.next(undefined);
      }),
      switchMapTo(
        this.httpClient.get<UniverseInfoResult[]>(this.configuration.url.toString()).pipe(
          catchError((error: HttpErrorResponse) => {
            if (error.status === 0) {
              this.universesErrorSubject$.next({
                network: true,
              });
            } else {
              this.universesErrorSubject$.next({
                internal: true,
              });
            }
            return of(undefined);
          }),
        ),
      ),
      map(UniverseMetaDataService.transform),
      shareReplay(1),
    );
  }

  private static transform(universeInfoResult?: UniverseInfoResult[]): UniverseMetaData[] | undefined {
    if (universeInfoResult === undefined) {
      return;
    }

    return universeInfoResult.map((universeInfo) => {

      const result: UniverseMetaData = {
        name: universeInfo.displayName,
        image: universeInfo.image,
        url: universeInfo.loginUrl,
        registered: true, // TODO
        settings: {
          maximumPlanetsWithoutResearch: parseInt(universeInfo.max_player_planets, 10),
          speeds: {
            build: universeInfo.building_speed,
            resource: parseInt(universeInfo.resource_multiplier, 10),
            fleet: universeInfo.fleet_speed,
          },
          debreeUnitPercent: {
            fleet: parseInt(universeInfo.tf_fleet, 10),
            defense: parseInt(universeInfo.tf_defense, 10),
          },
          defenseRebuildProbability: parseInt(universeInfo.rebuild, 10),
          startDate: new Date(universeInfo.universe_start_time * 1000),
        },
        metrics: {
          activePlayers: parseInt(universeInfo.active_players, 10),
          sentFleetsLast24h: parseInt(universeInfo.flying_fleets, 10),
        },
      };

      if (universeInfo.universe_end_time) {
        result.settings.endDate = new Date(universeInfo.universe_end_time * 1000);
      }

      if (universeInfo.close_start_time && universeInfo.close_start_time * 1000 < Date.now()) {
        const openDate = new Date(universeInfo.close_end_time * 1000);
        if (openDate > new Date()) {
          result.closed = {
            until: openDate,
            reason: universeInfo.close_reason, // TODO: translate this in the angular service and make the languages transparent for the consumer
          };
        }
      }

      return result;
    });
  }

  public refresh() {
    this.refreshUniverses$.next();
  }

}
