<nav>
  <a routerLink="contact" [routerLinkActive]="'active'" [routerLinkActiveOptions]="{exact:true}" translate>link.contact</a>
  <a href="/standalone/de/privacy.html" target="_blank" translate>link.privacy_notice</a>
  <button class="button seamless" (click)="openPrivacyPreferences()" translate>link.privacy_preferences</button>
  <span class="end">
    <a href="https://www.youtube.com/channel/UCU3qlqhIi1WFu90UyzuZfPQ" aria-label="Youtube"><fa-icon [icon]="faYoutube"></fa-icon></a>
    <a href="https://www.facebook.com/Xorbit.space" aria-label="Facebook"><fa-icon [icon]="faFacebookF"></fa-icon></a>
    <a href="https://twitter.com/XOrbitSpace" aria-label="Twitter"><fa-icon [icon]="faTwitter"></fa-icon></a>
    <a href="https://www.instagram.com/xorbit.space" aria-label="Instagram"><fa-icon [icon]="faInstagram"></fa-icon></a>
    <span class="version">v{{version}}</span>
    <select (change)="changeLanguage($any($event).target.value)" [attr.aria-label]="'action.select_language' | translate">
      <option *ngFor="let language of languages" [selected]="language === currentLanguage" [value]="language">{{languageNames[language]}}</option>
    </select>
  </span>
</nav>
