<app-privacy-consent-selector slot="header-top"></app-privacy-consent-selector>
<app-menu>
  <app-navigation slot="header-center"></app-navigation>
  <app-navigation slot="side"></app-navigation>
</app-menu>
<main>
  <router-outlet></router-outlet>
</main>
<footer>
  <app-footer-navigation></app-footer-navigation>
</footer>
