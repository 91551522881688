import {DOCUMENT, isPlatformServer} from '@angular/common';
import {Inject, Injectable, InjectionToken, OnDestroy, PLATFORM_ID} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';
import {merge, Observable, Subject} from 'rxjs';
import {filter, takeUntil} from 'rxjs/operators';
import { UserConsentService } from './user-consent.service';

export interface GoogleTagManagerServiceConfigurationInterface {
  id: string;
  pageViewEventName: string;
  preview?: {
    name: string;
    auth: string;
  };
}
export const GoogleTagManagerServiceConfiguration = new InjectionToken<GoogleTagManagerServiceConfigurationInterface>('googleTagManagerServiceConfiguration');

export interface GoogleTagManagerEvent {
  event: string;
  [key: string]: string | number;
}

const googleTagManagerDataLayerName = 'googleTagManagerDataLayer';

type WindowWithDataLayer = {
  [googleTagManagerDataLayerName]: Array<GoogleTagManagerEvent>;
}

@Injectable({
  providedIn: 'root',
})
export class GoogleTagManagerService implements OnDestroy {

  public consent = this.userConsentService.forName('Google Tag Manager');

  private ngUnsubscribe$ = new Subject<void>();
  private loaded$ = new Subject<void>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
    @Inject(DOCUMENT) private document: Document,
    @Inject(GoogleTagManagerServiceConfiguration) private configuration: GoogleTagManagerServiceConfigurationInterface,
    private userConsentService: UserConsentService,
    private router: Router,
  ) {}

  public init() {
    // Only initialize the gtm on the client
    if (isPlatformServer(this.platformId)) {
      return;
    }

    (window as unknown as WindowWithDataLayer)[googleTagManagerDataLayerName] = [];
    (window as unknown as WindowWithDataLayer)[googleTagManagerDataLayerName].push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    });

    if (!this.configuration) {
      return;
    }

    this.consent.given$.pipe(takeUntil(merge(this.ngUnsubscribe$, this.loaded$))).subscribe((given) => {
      if (!given) {
        return;
      }

      const script = this.document.createElement('script');
      script.src = `https://www.googletagmanager.com/gtm.js?id=${this.configuration.id}&l=${googleTagManagerDataLayerName}`;
      if (this.configuration.preview) {
        script.src += `&gtm_auth=${this.configuration.preview.auth}&gtm_preview=${this.configuration.preview.name}`;
      }
      script.async = true;
      this.document.body.appendChild(script);

      this.loaded$.complete();
    });

    (this.router.events.pipe(
      filter((event): event is NavigationEnd => event instanceof NavigationEnd),
      takeUntil(this.ngUnsubscribe$),
    ) as Observable<NavigationEnd>).subscribe(() => {
      this.triggerPageView();
    });
  }

  public triggerPageView() {
    this.triggerEvent({
      event: this.configuration.pageViewEventName,
    });
  }

  public triggerEvent(event: GoogleTagManagerEvent) {
    (window as unknown as WindowWithDataLayer)[googleTagManagerDataLayerName].push(event);
  }

  public ngOnDestroy() {
    this.ngUnsubscribe$.complete();
  }

}
