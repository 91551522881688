import { isPlatformBrowser } from '@angular/common';
import { Inject, Injectable, PLATFORM_ID } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

class UserConsent {

  private readonly localStoragePrefix = 'User consent: ';
  private readonly key: string;

  private givenSubject$ = new BehaviorSubject<boolean>(false);
  public given$ = this.givenSubject$.asObservable();

  constructor(private isBrowser: boolean, name: string, private lastChange?: Date) {
    this.key = this.localStoragePrefix + name;
    this.refresh();
  }

  private refresh(): void {
    if (!this.isBrowser) {
      return;
    }

    const stored = localStorage.getItem(this.key);
    this.givenSubject$.next(stored === null ? false : this.lastChange === undefined ? true : new Date(stored) <= this.lastChange);
  }

  public give(): void {
    if (!this.isBrowser) {
      return;
    }

    localStorage.setItem(this.key, new Date().toISOString());
    this.refresh();
  }

  public revoke(): void {
    if (!this.isBrowser) {
      return;
    }

    localStorage.removeItem(this.key);
    this.refresh();
  }

}

@Injectable({
  providedIn: 'root',
})
export class UserConsentService {

  private consentInstances = new Map<string, UserConsent>();

  constructor(
    @Inject(PLATFORM_ID) private platformId: string,
  ) {}

  public forName(name: string, lastChange?: Date): UserConsent {
    if (!this.consentInstances.has(name)) {
      this.consentInstances.set(name, new UserConsent(isPlatformBrowser(this.platformId), name, lastChange));
    }

    return this.consentInstances.get(name) as UserConsent;
  }

}
