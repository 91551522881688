const ACCESS_TOKEN_NAME = 'identity-access-token';

export interface AccessTokenClaims {
  jti: string; // ID
  sub: string; // Subject
  aud: string; // Audience
  iat: number; // IssuedAt
  exp: number; // ExpiresAt
  perm: string[]; // Permissions
}

export class AccessToken {
  private static tokens: { [key: string]: string };

  constructor(private realm: string) {
    // Initially fill the tokens map
    if (AccessToken.tokens === undefined) {
      AccessToken.tokens =
        JSON.parse(sessionStorage.getItem(ACCESS_TOKEN_NAME)) || {};
    }
  }

  public static removeAll(): void {
    sessionStorage.removeItem(ACCESS_TOKEN_NAME);
  }

  private static saveAll(): void {
    sessionStorage.setItem(
        ACCESS_TOKEN_NAME,
        JSON.stringify(AccessToken.tokens),
    );
  }

  public isValid(): boolean {
    const jwtClaims = this.getClaims();
    if (jwtClaims === null) {
      return false;
    }

    // Return if the token has already expired
    return jwtClaims.exp * 1000 > Date.now();
  }

  public expire(): void {
    delete AccessToken.tokens[this.realm];
    AccessToken.saveAll();
  }

  public store(token: string): void {
    AccessToken.tokens[this.realm] = token;
    AccessToken.saveAll();
  }

  public get(): string {
    return AccessToken.tokens[this.realm];
  }

  public getClaims(): AccessTokenClaims {
    const storedAccessToken = this.get();

    // Check if the access token exists
    if (storedAccessToken === null || storedAccessToken === undefined) {
      return null;
    }

    // Parse the jwtClaims and return them
    return JSON.parse(atob(storedAccessToken.split('.')[1]));
  }
}
