import {Component, OnDestroy} from '@angular/core';
import {Router} from '@angular/router';
import {LangChangeEvent, TranslateService} from '@ngx-translate/core';
import {Subject} from 'rxjs';
import {takeUntil} from 'rxjs/operators';
import {version} from 'src/version';
import {UserConsentService} from '../../providers/user-consent.service';
import {necessaryConsentName} from '../privacy-consent-selector/privacy-consent-selector.component';
import {faFacebookF, faTwitter, faYoutube, faInstagram} from '@fortawesome/free-brands-svg-icons';

@Component({
  selector: 'app-footer-navigation',
  templateUrl: './footer-navigation.component.html',
  styleUrls: ['./footer-navigation.component.scss'],
})
export class FooterNavigationComponent implements OnDestroy {

  public faFacebookF = faFacebookF;
  public faTwitter = faTwitter;
  public faYoutube = faYoutube;
  public faInstagram = faInstagram;

  public readonly version = version;
  public readonly languages = this.translateService.langs;
  public currentLanguage = this.translateService.currentLang;

  public languageNames: {[language: string]: string} = {
    en: 'English',
    de: 'Deutsch',
    fr: 'Français',
    es: 'Español',
    it: 'Italiano',
    ru: 'Русский',
    pt: 'Português',
    nl: 'Nederlands',
    pl: 'Polski',
    tr: 'Türkçe',
    hr: 'Hrvatski',
    cs: 'Česky',
  };

  private readonly necessaryConsent = this.userConsent.forName(necessaryConsentName);

  private ngUnsubscribe$ = new Subject<void>();

  constructor(private router: Router, private translateService: TranslateService, private userConsent: UserConsentService) {
    this.translateService.onLangChange.pipe(takeUntil(this.ngUnsubscribe$)).subscribe((langChangeEvent: LangChangeEvent) => {
      this.currentLanguage = langChangeEvent.lang;
    });
  }

  public ngOnDestroy(): void {
    this.ngUnsubscribe$.complete();
  }

  public changeLanguage(language: string) {
    const urlParts = this.router.url.split('/');
    urlParts[1] = language;
    this.router.navigateByUrl(urlParts.join('/'));
  }

  public openPrivacyPreferences() {
    this.necessaryConsent.revoke();
  }
}
