import {APP_INITIALIZER, ErrorHandler, Inject, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';
import {HttpClient, HttpClientModule} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {Router, RouterModule} from '@angular/router';
import {ServiceWorkerModule} from '@angular/service-worker';
import {TransferHttpCacheModule} from '@nguniversal/common';

import {LangChangeEvent, TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import * as moment from 'moment';
import {IdentityModule, identityServiceConfigurationToken} from '@skforge-ug/ngx-identity';
import {RECAPTCHA_BASE_URL, RECAPTCHA_SETTINGS, RecaptchaModule, RecaptchaSettings} from 'ng-recaptcha';
import * as Sentry from '@sentry/angular-ivy';

import {environment} from '../environments/environment';
import {AppRoutingModule} from './app-routing.module';
import {AppComponent} from './app.component';
import {GoogleTagManagerService, GoogleTagManagerServiceConfiguration} from './providers/google-tag-manager.service';
import {NewsServiceConfigurationToken} from './providers/news.service';
import {UniverseMetaDataServiceConfigurationToken} from './providers/universe-meta-data.service';
import {SharedModule} from './shared/shared.module';
import {ShellModule} from './shell/shell.module';

import {DOCUMENT, registerLocaleData} from '@angular/common';
import localeDe from '@angular/common/locales/de';
import localeFr from '@angular/common/locales/fr';
import localeEs from '@angular/common/locales/es';
import localePt from '@angular/common/locales/pt';
import localeRu from '@angular/common/locales/ru';
import localeIt from '@angular/common/locales/it';
import localeNl from '@angular/common/locales/nl';
import localePl from '@angular/common/locales/pl';
import localeTr from '@angular/common/locales/tr';
import localeHr from '@angular/common/locales/hr';
import localeCs from '@angular/common/locales/cs';
registerLocaleData(localeDe);
registerLocaleData(localeFr);
registerLocaleData(localeEs);
registerLocaleData(localePt);
registerLocaleData(localeRu);
registerLocaleData(localeIt);
registerLocaleData(localeNl);
registerLocaleData(localePl);
registerLocaleData(localeTr);
registerLocaleData(localeHr);
registerLocaleData(localeCs);

// AoT requires an exported function for factories
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}

@NgModule({
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    TransferHttpCacheModule,
    BrowserAnimationsModule,
    SharedModule,
    HttpClientModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    RecaptchaModule,
    IdentityModule,
    ShellModule,
    AppRoutingModule,
    ServiceWorkerModule.register('ngsw-worker.js', { enabled: environment.production }),
    RouterModule,
  ],
  providers: [
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler({
        showDialog: false,
      }),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    {
      provide: APP_INITIALIZER,
      // eslint-disable-next-line @typescript-eslint/no-empty-function
      useFactory: () => () => {},
      deps: [Sentry.TraceService],
      multi: true,
    },
    {
      provide: RECAPTCHA_SETTINGS,
      useValue: {
        siteKey: environment.recaptchaKey,
        size: 'invisible',
      } as RecaptchaSettings,
    },
    {
      provide: RECAPTCHA_BASE_URL,
      useValue: 'https://recaptcha.net/recaptcha/api.js', // use recaptcha.net script source since some countries block google.com domains
    },
    {
      provide: UniverseMetaDataServiceConfigurationToken, useValue: environment.universeMetaDataService,
    },
    {
      provide: identityServiceConfigurationToken, useValue: environment.identity,
    },
    {
      provide: NewsServiceConfigurationToken, useValue: environment.news,
    },
    {
      provide: GoogleTagManagerServiceConfiguration, useValue: environment.googleTagManager,
    },
  ],
  declarations: [AppComponent],
  bootstrap: [AppComponent],
})
export class AppModule {

  constructor(
    @Inject(DOCUMENT) private document: Document,
    translateService: TranslateService,
    googleTagManagerService: GoogleTagManagerService,
  ) {
    // Use the first language as default language, don't use setDefaultLang as that always loads that language
    const defaultLanguage = 'en';
    translateService.addLangs([defaultLanguage, 'de']);
    translateService.addLangs([defaultLanguage, 'fr']);
    translateService.addLangs([defaultLanguage, 'es']);
    translateService.addLangs([defaultLanguage, 'pt']);
    translateService.addLangs([defaultLanguage, 'ru']);
    translateService.addLangs([defaultLanguage, 'it']);
    translateService.addLangs([defaultLanguage, 'nl']);
    translateService.addLangs([defaultLanguage, 'pl']);
    translateService.addLangs([defaultLanguage, 'tr']);
    translateService.addLangs([defaultLanguage, 'hr']);
    translateService.addLangs([defaultLanguage, 'cs']);

    translateService.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.document.documentElement.lang = langChangeEvent.lang;
      moment.locale(langChangeEvent.lang);
    });

    googleTagManagerService.init();
  }

}
