import {Inject, NgModule} from '@angular/core';
import {Title} from '@angular/platform-browser';
import {DOCUMENT} from '@angular/common';
import {ActivatedRoute, NavigationEnd, Router, RouterModule, Routes} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';
import {Subscription} from 'rxjs';
import {filter, map, mergeMap} from 'rxjs/operators';
import {AuthenticateGuardOptions, AuthenticatedGuard, authenticateGuardRouteDateName} from './providers/authenticated.guard';
import {LanguageRedirectGuard} from './providers/language-redirect.guard';
import {SharedModule} from './shared/shared.module';
import {ShellComponent} from './shell/shell.component';

const routes: Routes = [
  { path: '', pathMatch: 'full', canActivate: [LanguageRedirectGuard], children: [] },
  {
    path: ':language', canActivate: [LanguageRedirectGuard], component: ShellComponent, children: [
      {
        path: '',
        loadChildren: () => import('./pages/home/home.module').then((mod) => mod.HomeModule),
      },
      {
        path: 'login',
        canActivate: [AuthenticatedGuard],
        data: {[authenticateGuardRouteDateName]: {unauthenticated: true, redirect: ['universes']} as AuthenticateGuardOptions},
        loadChildren: () => import('./pages/login/login.module').then((mod) => mod.LoginModule),
      },
      {
        path: 'about',
        loadChildren: () => import('./pages/about/about.module').then((mod) => mod.AboutModule),
      },
      {
        path: 'activate',
        loadChildren: () => import('./pages/activate/activate.module').then((mod) => mod.ActivateModule),
      },
      {
        path: 'change-password',
        loadChildren: () => import('./pages/change-password/change-password.module').then((mod) => mod.ChangePasswordModule),
      },
      {
        path: 'contact',
        loadChildren: () => import('./pages/contact/contact.module').then((mod) => mod.ContactModule),
      },
      {
        path: 'account',
        canActivate: [AuthenticatedGuard],
        data: {[authenticateGuardRouteDateName]: {unauthenticated: false, redirect: []} as AuthenticateGuardOptions},
        loadChildren: () => import('./pages/account/account.module').then((mod) => mod.AccountModule),
      },
      {
        path: '**',
        loadChildren: () => import('./pages/not-found/not-found.module').then((mod) => mod.NotFoundModule),
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    scrollOffset: [0, 60],
    initialNavigation: 'enabledBlocking'
}),
    SharedModule,
  ],
  exports: [
    RouterModule,
  ],
  providers: [
    AuthenticatedGuard,
    LanguageRedirectGuard,
  ],
})
export class AppRoutingModule {

  constructor(
    @Inject(DOCUMENT) document: Document,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private titleService: Title,
    private translate: TranslateService,
  ) {
    const suffix = document.title;

    let titleSubscription: Subscription | undefined;

    this.router.events.pipe(
      filter((event) => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) {
          route = route.firstChild;
        }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data),
    ).subscribe((event) => {
      if (titleSubscription !== undefined) {
        titleSubscription.unsubscribe();
      }
      if (!event['title']) {
        this.titleService.setTitle(suffix);
        return;
      }
      titleSubscription = translate.get(event['title']).subscribe((title: string) => {
        this.titleService.setTitle(`${title} | ${suffix}`);
      });
    });
  }

}
