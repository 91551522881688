import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, Router, RouterStateSnapshot } from '@angular/router';
import {IdentityService} from '@skforge-ug/ngx-identity';

export interface AuthenticateGuardOptions {
  unauthenticated?: boolean;
  redirect?: unknown[];
}

export const authenticateGuardRouteDateName = 'authenticatedGuardOptions';

@Injectable()
export class AuthenticatedGuard  {

  private static rerunLast: () => Promise<boolean>;

  constructor(private identityService: IdentityService, private router: Router) {
    this.identityService.authenticated.subscribe(() => {
      if (AuthenticatedGuard.rerunLast !== undefined) {
        AuthenticatedGuard.rerunLast();
      }
    });
  }

  public async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
    AuthenticatedGuard.rerunLast = (): Promise<boolean> => {
      return this.canActivate(next, state);
    };

    const options = next.data[authenticateGuardRouteDateName] as AuthenticateGuardOptions;

    let canActivate: boolean;
    if (options.unauthenticated === true) {
      canActivate = !this.identityService.authenticated.value;
      if (canActivate === false) {
        canActivate = !await this.identityService.get().ensureSession();
      }
    } else {
      canActivate = this.identityService.authenticated.value;
    }

    if (canActivate === false && options.redirect) {
      this.router.navigate([
        ...(next.parent ? next.parent.url.map((segment) => segment.path) : []),
        ...options.redirect,
      ]);
    }

    return new Promise<boolean>((resolve) => {
      resolve(canActivate);
    });
  }

}
